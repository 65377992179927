<app-payment-item
    [isPaymentLinked]="wts.isCardAvailable(initData)"
    (clickContainer)="onAddCreditCard()">
    <app-payment-item-header>
        {{ wts.getI18nLabel(i18nBasePath, 'title') }}
    </app-payment-item-header>

    <ng-container *ngIf="wts.isCardAvailable(initData)">
        <app-payment-item-detail>
            <div
                class="card-icon"
                *ngIf="getCardIcon(creditCardResponse?.card?.cardType) as cardIcon">
                <div class="card-icon__item">
                    <svg-icon-sprite [src]="cardIcon"></svg-icon-sprite>
                </div>
            </div>

            {{ wts.getI18nLabel(i18nBasePath, 'details.number') }}
            {{ creditCardResponse?.card?.creditCardNumber ?? '' | lastDigits : 16 }}
        </app-payment-item-detail>
    </ng-container>

    <app-payment-item-detail>
        <span class="payment-subtitle payment-subtitle-faded">
            {{ limits?.creditCardPaymentsFee | numberTwoDigits }}% {{ wts.getI18nLabel(i18nBasePath, 'details.fee') }}
        </span>
    </app-payment-item-detail>

    <app-payment-item-actions>
        <button
            nz-button
            nzSize="small"
            type="button"
            (click)="deleteCCConfirmVisible = true">
            {{ wts.getI18nLabel(i18nBasePath, 'actions.update') }}
        </button>
    </app-payment-item-actions>
</app-payment-item>

<div class="payment-subtitle payment-subtitle-faded">
    {{ wts.getI18nLabel(i18nBasePath, 'alerts.limit', { limit: limits?.maxCreditCardTransferAmountCents / 100 | price }) }}
</div>

<app-confirm-modal
    *ngIf="deleteCCConfirmVisible"
    [confirmBtnDanger]="false"
    (confirm)="onUnlinkCreditCard()"
    (cancel)="deleteCCConfirmVisible = false">
    <ng-container modalTitle>
        <span [innerHtml]="wts.getI18nLabel(i18nBasePath, 'alerts.confirmUpdate.title')"></span>
    </ng-container>

    <ng-container modalContent>
        <p
            class="ant-modal__descr"
            [innerHtml]="wts.getI18nLabel(i18nBasePath, 'alerts.confirmUpdate.descr')"></p>
    </ng-container>
</app-confirm-modal>

<ng-template #tplStripeModalTitle>
    {{ wts.getI18nLabel(i18nBasePath, 'addMode') }}
    <div class="card-icon card-icon-header">
        <ng-container *ngFor="let cardBrandIcon of cardBrandIcons">
            <div class="card-icon__item">
                <svg-icon-sprite [src]="cardBrandIcon.icon"></svg-icon-sprite>
            </div>
        </ng-container>
    </div>
</ng-template>
