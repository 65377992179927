import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { BazaFormValidatorService } from '@scaliolabs/baza-core-ng';
import { AccountVerificationStep, BazaNcBootstrapDto } from '@scaliolabs/baza-nc-shared';
import {
    BazaLinkUtilSharedService,
    BazaWebUtilSharedService,
    ValidationsPreset,
    i18nValidationTypesEnum,
    whitespaceValidator,
} from '@scaliolabs/baza-web-utils';
import { Observable } from 'rxjs';
import { filter, take, tap } from 'rxjs/operators';
import { ApplyInvestorProfile, Verification, VerificationState, InvestorProfile, VerificationInvestorForm } from '../data-access';

@Component({
    selector: 'app-verification-stepper-investor',
    templateUrl: './stepper-investor.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VerificationInvestorComponent implements OnInit, OnChanges {
    @Input()
    initData: BazaNcBootstrapDto;

    @Input()
    proceedToNextStep?: boolean;

    @Output()
    moveToNextStep: EventEmitter<void> = new EventEmitter();

    verification$: Observable<Verification>;
    fileUploading: boolean;
    verificationInvestorForm: VerificationInvestorForm;

    i18nBasePath = 'dwvf.investor';
    i18nFormPath = `${this.i18nBasePath}.form`;
    i18nFormFieldsPath = `${this.i18nBasePath}.form.fields`;
    vfConfig = this.store.selectSnapshot(VerificationState.vfConfig);

    constructor(
        private readonly fb: UntypedFormBuilder,
        private readonly cdr: ChangeDetectorRef,
        private readonly router: Router,
        private readonly route: ActivatedRoute,
        private readonly store: Store,
        public readonly bazaFormValidatorService: BazaFormValidatorService,
        public readonly uts: BazaLinkUtilSharedService,
        public readonly wts: BazaWebUtilSharedService,
    ) {
        this.onFormReset();
    }

    public ngOnInit(): void {
        this.verification$ = this.store.select(VerificationState.verification).pipe(
            filter((res) => !!res),
            take(1),
            tap((verification) => {
                this.fileUploading =
                    verification?.currentStep === AccountVerificationStep.RequestDocuments ||
                    verification?.currentStep === AccountVerificationStep.RequestSSNDocument
                        ? true
                        : false;

                const investorProfile = verification.investorProfile;
                this.verificationInvestorForm.patchValue(investorProfile);

                if (investorProfile) {
                    this.checkDwollaConsentCheckbox();
                    this.checkFinraRelationship();

                    this.verificationInvestorForm.patchValue(investorProfile);
                    this.verificationInvestorForm.updateValueAndValidity();
                }

                this.cdr.markForCheck();
            }),
        );
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (!(changes?.proceedToNextStep?.firstChange ?? true) && (changes?.proceedToNextStep?.currentValue ?? true)) {
            this.router
                .navigate(['.'], {
                    relativeTo: this.route,
                    queryParams: {
                        updated: true,
                    },
                    queryParamsHandling: 'merge',
                })
                .then(() => {
                    this.redirectToNextStep();
                });
        }

        if (!changes?.initData?.firstChange) {
            this.checkDwollaConsentCheckbox();
        }
    }

    // onFormReset
    public onFormReset(): void {
        this.verificationInvestorForm = this.fb.group({
            isAccreditedInvestor: ['', Validators.required],
            isAssociatedWithFINRA: ['', Validators.required],
        }) as VerificationInvestorForm;
    }

    // onFormSubmit
    public onFormSubmit(investorFormEl: HTMLFormElement): void {
        if (this.bazaFormValidatorService.isFormValid(this.verificationInvestorForm, investorFormEl)) {
            const data: InvestorProfile = {
                ...this.verificationInvestorForm.value,
                finraRelationship: this.verificationInvestorForm.value['finraRelationship'] ?? null,
            };

            this.store.dispatch(new ApplyInvestorProfile(data)).subscribe(() => {
                this.moveToNextStep.next();
            });
        }
    }

    public backToPreviousStep() {
        const queryParams = {
            back: this.vfConfig?.back,
        };

        if (this.vfConfig?.redirect) {
            queryParams['redirect'] = this.vfConfig?.redirect;
        }

        this.router.navigate([this.vfConfig?.vfLink, 'info'], {
            queryParams: queryParams,
        });
    }

    redirectToNextStep() {
        const vfConfig = this.store.selectSnapshot(VerificationState.vfConfig);
        this.router.navigateByUrl(vfConfig.redirect ?? vfConfig.back);
    }

    public getErrorMessage(control: UntypedFormControl, controlName: string): string {
        const validationsPreset: ValidationsPreset = new Map([
            ['isAccreditedInvestor', [{ key: i18nValidationTypesEnum.required }]],
            ['isAssociatedWithFINRA', [{ key: i18nValidationTypesEnum.required }]],
            ['dwollaConsentProvided', [{ key: i18nValidationTypesEnum.required }]],
            [
                'finraRelationship',
                [{ key: i18nValidationTypesEnum.required }, { key: i18nValidationTypesEnum.maxlength, params: { length: 100 } }],
            ],
        ]);

        return this.wts.geti18nValidationErrorMessages({
            control,
            controlName,
            i18nFormFieldsPath: this.i18nFormFieldsPath,
            i18nGenericValidationsPath: `${this.i18nFormPath}.genericValidators`,
            validationsPreset,
        });
    }

    acceptConsent(event: Event) {
        if (!(event?.target as HTMLElement)?.classList?.toString()?.toLocaleLowerCase()?.includes('linkify')) {
            const consentCheckboxCtrl = this.verificationInvestorForm.get('dwollaConsentProvided');
            consentCheckboxCtrl.setValue(!consentCheckboxCtrl.value);
            consentCheckboxCtrl.markAsDirty();
            consentCheckboxCtrl.updateValueAndValidity();
        }
    }

    checkDwollaConsentCheckbox() {
        if (this.vfConfig?.dwollaSupport) {
            if (this.showConsentCheckbox) {
                if (!this.consentCheckboxCtrl) {
                    this.addDwollaConsentCheckboxAndValidation();
                }
            } else {
                this.removeDwollaConsentCheckboxAndValidation();
            }
        }
    }

    public get showConsentCheckbox() {
        return (
            this.vfConfig?.dwollaSupport &&
            !this.initData?.investorAccount?.status?.isForeign &&
            !this.initData?.investorAccount?.isAccountVerificationCompleted
        );
    }

    public get consentCheckboxCtrl() {
        return this.verificationInvestorForm.controls['dwollaConsentProvided'];
    }

    private removeDwollaConsentCheckboxAndValidation() {
        this.verificationInvestorForm.removeControl('dwollaConsentProvided');
        this.verificationInvestorForm.updateValueAndValidity();
    }

    private addDwollaConsentCheckboxAndValidation() {
        this.verificationInvestorForm.addControl('dwollaConsentProvided', new UntypedFormControl(false, [Validators.requiredTrue]));
        this.verificationInvestorForm.updateValueAndValidity();
    }

    checkFinraRelationship() {
        if (this.showFinraRelationship) {
            this.addFinraRelationshipAndValidation();
        }
    }

    public get showFinraRelationship() {
        return this.wts.appSupportsRegCF(this.initData) && this.verificationInvestorForm?.controls['isAssociatedWithFINRA']?.value === true;
    }

    public get finraRelationshipHasError() {
        return !this.finraRelationshipshipCtrl?.pristine && this.finraRelationshipshipCtrl?.dirty && this.finraRelationshipshipCtrl?.errors;
    }

    public get finraRelationshipshipCtrl() {
        return this.verificationInvestorForm.controls['finraRelationship'];
    }

    onFinraAssociationChange(isAssociated) {
        if (this.wts.appSupportsRegCF(this.initData)) {
            if (!isAssociated) {
                this.removeFinraRelationship();
            } else {
                this.addFinraRelationshipAndValidation();
            }
        }
    }

    private addFinraRelationshipAndValidation() {
        this.verificationInvestorForm.addControl(
            'finraRelationship',
            new UntypedFormControl('', Validators.compose([Validators.required, whitespaceValidator(), Validators.maxLength(100)])),
        );
        this.verificationInvestorForm.updateValueAndValidity();
    }

    private removeFinraRelationship() {
        this.verificationInvestorForm.removeControl('finraRelationship');
        this.verificationInvestorForm.updateValueAndValidity();

        this.cdr.detectChanges();
    }

    public get consentLinksConfig(): string {
        return `${this.i18nFormFieldsPath}.dwollaConsentProvided.linkConfig`;
    }
}
