import { CommonModule } from '@angular/common';
import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { FormsModule, ReactiveFormsModule, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import {
    AMOUNT_VALIDATION_CONFIG,
    BazaWebUtilSharedService,
    Message,
    UtilModule,
    ValidationsPreset,
    i18nValidationTypesEnum,
} from '@scaliolabs/baza-web-utils';
import { NzAlertModule } from 'ng-zorro-antd/alert';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzInputNumberModule } from 'ng-zorro-antd/input-number';

@Component({
    selector: 'app-add-funds-form',
    templateUrl: './add-funds-form.component.html',
    standalone: true,
    imports: [CommonModule, UtilModule, FormsModule, ReactiveFormsModule, NzFormModule, NzInputNumberModule, NzAlertModule],
})
export class DwollaAddFundsFormComponent {
    @Input()
    addFundsForm: UntypedFormGroup;

    @Input()
    addFundsError: Message;

    @ViewChild('addFundsFormEl') addFundsFormRef: ElementRef;

    i18nBasePath = 'uic.addFunds.form';
    i18nFormFieldsPath = `${this.i18nBasePath}.fields`;
    amountConfig = AMOUNT_VALIDATION_CONFIG;

    public get amountValidationMaxLength() {
        return this.wts.dollarParse(this.amountConfig.maxValue.toString()).length;
    }

    constructor(public readonly wts: BazaWebUtilSharedService) {
        this.addFundsError = {
            text: 'something',
            type: 'error',
        };
    }

    public getErrorMessage(control: UntypedFormControl, controlName: string): string {
        const validationsPreset: ValidationsPreset = new Map([['amount', [{ key: i18nValidationTypesEnum.required }]]]);

        return this.wts.geti18nValidationErrorMessages({
            control,
            controlName,
            i18nFormFieldsPath: this.i18nFormFieldsPath,
            i18nGenericValidationsPath: `${this.i18nBasePath}.genericValidators`,
            validationsPreset,
        });
    }

    get contactLinkConfig(): string {
        return `${this.i18nBasePath}.alerts.transferError.linkConfig`;
    }
}
