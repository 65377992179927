<div *ngIf="config?.isAddModeOn">
    <button
        class="payment-btn payment-btn-inverse payment-btn-buy"
        [class.payment-btn-bordered]="config?.style === PAYMENT_BUTTON_STYLE.Bordered"
        nz-button
        nzBlock
        (click)="upsertBank.emit()">
        <div class="payment-btn__icon">
            <i class="icon icon-bank-dwolla"></i>
        </div>

        <div class="payment-btn__title">
            {{ wts.getI18nLabel(i18nBasePath, 'addMode.plaid') }}

            <div
                *ngIf="wts.getI18nLabel(i18nBasePath, 'addMode.subtitle')"
                class="payment-subtitle payment-subtitle-faded">
                {{ wts.getI18nLabel(i18nBasePath, 'addMode.subtitle') }}
            </div>
        </div>
        <div class="payment-arrow">
            <i class="icon icon-angle-right icon-wt"></i>
        </div>
    </button>
</div>

<div *ngIf="config?.isEditModeOn">
    <div class="payment-buy-info">
        <ul class="info-list">
            <li class="info-list__item">
                <div
                    class="info-list__label"
                    [innerHTML]="wts.getI18nLabel(this.i18nBasePath, 'editMode.account.name') | sanitizeHtml"></div>
                <div
                    class="info-list__text"
                    [innerHTML]="srcData?.details?.accountName"></div>
            </li>

            <li class="info-list__item">
                <div
                    class="info-list__label"
                    [innerHTML]="wts.getI18nLabel(this.i18nBasePath, 'editMode.account.number') | sanitizeHtml"></div>
                <div
                    class="info-list__text"
                    [innerHTML]="srcData?.details?.accountNumber | lastDigits"></div>
            </li>

            <li class="info-list__item">
                <div
                    class="info-list__label"
                    [innerHTML]="wts.getI18nLabel(this.i18nBasePath, 'editMode.account.type') | sanitizeHtml"></div>
                <div
                    class="info-list__text"
                    [innerHTML]="srcData?.details?.accountType"></div>
            </li>
        </ul>
    </div>
</div>
