import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { BazaAuthDataAccess } from '@scaliolabs/baza-core-data-access';
import { JwtService, genericRetryStrategy } from '@scaliolabs/baza-core-ng';
import { EffectsUtil } from '@scaliolabs/baza-web-utils';
import { combineLatest } from 'rxjs';
import { retryWhen, switchMap, tap } from 'rxjs/operators';
import { AccountInit, AccountUnset } from '../account';
import { GetItems } from '../item';
import { ClearUser, Login, Logout, StartApp } from './actions';

export interface AuthStateModel {
    authenticated?: boolean;
}

@State<AuthStateModel>({
    name: 'auth',
    defaults: {
        authenticated: undefined,
    },
})
@Injectable()
export class AuthState {
    constructor(
        private readonly dataAccess: BazaAuthDataAccess,
        private readonly effectsUtil: EffectsUtil,
        private readonly jwtService: JwtService,
    ) {}

    @Selector()
    static authenticated(state: AuthStateModel) {
        return state.authenticated;
    }

    @Action(Login, { cancelUncompleted: true })
    login(ctx: StateContext<AuthStateModel>, action: Login) {
        return this.dataAccess.auth(action.data).pipe(
            retryWhen(genericRetryStrategy()),
            switchMap((response) => {
                this.jwtService.setJwt(
                    {
                        accessToken: response.accessToken,
                        refreshToken: response.refreshToken,
                    },
                    response.jwtPayload,
                );

                ctx.patchState({ authenticated: true });
                return ctx.dispatch(new AccountInit()).pipe(
                    tap(() => {
                        ctx.dispatch(new GetItems());
                    }),
                );
            }),
            this.effectsUtil.tryCatchNone$(),
        );
    }

    @Action(ClearUser)
    clearUser(ctx: StateContext<AuthStateModel>) {
        ctx.patchState({ authenticated: false });
        ctx.dispatch(new AccountUnset());
        this.jwtService.destroy();
    }

    @Action(Logout)
    logout(ctx: StateContext<AuthStateModel>) {
        return combineLatest([
            this.dataAccess.invalidateToken({ jwt: this.jwtService.jwt.accessToken }),
            this.dataAccess.invalidateToken({ jwt: this.jwtService.jwt.refreshToken }),
        ]).pipe(
            tap(() => {
                ctx.patchState({ authenticated: false });
                ctx.dispatch(new AccountUnset());
                this.jwtService.destroy();
            }),
        );
    }

    @Action(StartApp)
    startApp(ctx: StateContext<AuthStateModel>) {
        if (this.jwtService.hasJwt()) {
            ctx.patchState({ authenticated: true });
            ctx.dispatch(new AccountInit());
        }
    }
}
