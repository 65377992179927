import { Injectable, inject } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { BazaNcBootstrapDto, BazaNcOfferingRegulationType } from '@scaliolabs/baza-nc-shared';
import deepmerge from 'deepmerge';
import _ from 'lodash';
import { Subject } from 'rxjs';
import { CARD_BRAND_ICONS, LinkTypeEnum, ValidationsPreset, i18nValidationCheckReq } from '../models';
import { PriceNoRoundPipe } from '../pipes';
/**
 * A Baza level shared service for common utility functions
 */
@Injectable({ providedIn: 'root' })
export class BazaWebUtilSharedService {
    readonly ts = inject(TranslateService);
    readonly priceNoRound = inject(PriceNoRoundPipe);
    public readonly refreshInitData$: Subject<void> = new Subject<void>();

    dollarFormat = (value: number) => this.priceNoRound.transform(value);
    dollarParse = (value: string) => value.replace('$', '').replace(',', '');

    /**
     * Merges two config objects, `defaultConfig` and `overrideConfig`, into one.
     *
     * @param defaultConfig - The default config object.
     * @param overrideConfig - The config object that will override the default values.
     *
     * @returns The merged config object.
     *
     * @example
     *
     * const defaultConfig = {
     *   color: "blue",
     *   size: "large",
     *   shape: "round"
     * };
     *
     * const overrideConfig = {
     *   color: "red",
     *   shape: "square"
     * };
     *
     * const mergedConfig = mergeConfig(defaultConfig, overrideConfig);
     * console.log(mergedConfig);
     *
     * // Output:
     * // { color: "red", size: "large", shape: "square" }
     */
    public mergeConfig<ConfigType = Record<string, unknown>>(
        defaultConfig: ConfigType,
        overrideConfig: Partial<ConfigType> | Record<string, unknown>,
    ): ConfigType {
        const mergedObj = deepmerge.all([defaultConfig ?? {}, overrideConfig ?? {}]);
        const final = this.analyzeAndMergeNestedObjects(_.cloneDeep(mergedObj), overrideConfig) as ConfigType;
        return final;
    }

    /**
     * Analyzes and merges any type of nested objects in the `mergedObj` object, using values from the `overrideConfig`.
     *
     * @param mergedObj - The object that has been merged from `defaultConfig` and `overrideConfig`.
     * @param overrideConfig - The config object that will override the default values.
     *
     * @returns The merged config object with nested objects analyzed and merged.
     *
     * @remarks
     *
     * 1. This function analyzes the properties in the `mergedObj` object and merges any nested objects using the values from `overrideConfig`.
     * 2. The function uses the `getAllPaths` method to get all the paths of properties in `mergedObj`.
     * 3. For each path, the function checks if it ends with either "AppLink" or "ExtLink".
     * 4. If the path ends with "AppLink", the function only replaces the "commands" property in `mergedObj` with the corresponding value from `overrideConfig`.
     * 5. If the path ends with "ExtLink", the function replaces the entire link object in `mergedObj` with the corresponding value from `overrideConfig`.
     * 6. If the property is an array in both `mergedObj` and `overrideConfig`, the function replaces the property in `mergedObj` with the corresponding value from `overrideConfig`.
     */
    private analyzeAndMergeNestedObjects(
        mergedObj: Record<string, any>,
        overrideConfig: Partial<Record<string, any>>,
    ): Record<string, any> {
        const paths = this.getAllPaths(mergedObj);

        paths.forEach((pathToCheck) => {
            // get objects based on given path
            const dcMergedObj = _.get(mergedObj, pathToCheck);
            const isArrayDcMergedObj = _.isArray(dcMergedObj);

            const ovObj = _.get(overrideConfig, pathToCheck);
            const isArrayOvMergedObj = _.isArray(ovObj);

            const isLink = pathToCheck.endsWith(LinkTypeEnum.AppLink) || pathToCheck.endsWith(LinkTypeEnum.ExtLink);

            if (isLink) {
                const pathSubstr = pathToCheck.substring(0, pathToCheck.lastIndexOf('.'));
                const swappedLinkPathToCheck =
                    pathSubstr + '.' + (pathToCheck.includes(LinkTypeEnum.AppLink) ? LinkTypeEnum.ExtLink : LinkTypeEnum.AppLink);

                // if override config has the alternative link, replace the entire link in merged obj, from override config
                if (_.has(overrideConfig, swappedLinkPathToCheck)) {
                    mergedObj = _.omit(mergedObj, pathToCheck);
                }

                // if path has 'appLink', only replace commands in merged obj, from override config (if exsts)
                else if (pathToCheck.endsWith(LinkTypeEnum.AppLink)) {
                    if (ovObj?.commands ?? false) {
                        dcMergedObj.commands = ovObj.commands;
                        _.set(mergedObj, pathToCheck, dcMergedObj);
                    }
                }
            } else if (isArrayDcMergedObj && isArrayOvMergedObj) {
                _.set(mergedObj, pathToCheck, ovObj);
            }
        });

        return mergedObj;
    }

    /**
     * Gets all object paths for the given object.
     *
     * @param obj - The object to get paths for.
     *
     * @returns An array of all object paths.
     */
    private getAllPaths(obj: Record<string, unknown>): string[] {
        const objPaths = (this.getObjPathsRecursively(obj) ?? '').toString();

        return objPaths !== '' ? objPaths.split(',') : [];
    }

    /**
     * Recursively traverses an object and retrieves all paths of its properties.
     *
     * @param obj - The object to traverse.
     * @param currentPath - The current path being traversed.
     *
     * @remarks
     *
     * 1. The function loops through all the keys of the `obj` object.
     * 2. For each key, it creates a new path by concatenating the `currentPath` and the key.
     * 3. The function checks if the current value is an object and if it includes either "AppLink" or "ExtLink" in its path.
     * 4. If the current value is an object and includes either "AppLink" or "ExtLink" in its path and is not an array, the function adds the new path to the `resultPaths` array.
     * 5. If the current value is an array and does not include either "AppLink" or "ExtLink" in its path, the function adds the new path to the `resultPaths` array.
     * 6. If the current value is an object, the function recursively calls itself with the current value and the new path as arguments.
     */
    private getObjPathsRecursively(obj: Record<string, unknown>, currentPath = ''): string[] {
        let resultPaths: string[] = [];

        for (const key of Object.keys(obj)) {
            const currentValue = obj[`${key}`] as Record<string, unknown>;
            const newPath = currentPath ? `${currentPath}.${key}` : key;
            const isObject = _.isObject(currentValue);

            if (isObject) {
                const isLink = newPath.includes(LinkTypeEnum.AppLink) || newPath.includes(LinkTypeEnum.ExtLink);
                const isArray = _.isArray(currentValue);

                if (isLink && !isArray) {
                    resultPaths.push(newPath);
                }

                if (isArray && !isLink) {
                    resultPaths.push(newPath);
                    continue;
                }

                resultPaths = resultPaths.concat(this.getObjPathsRecursively(currentValue, newPath));
            }
        }

        return resultPaths;
    }

    public geti18nValidationErrorMessages({
        control,
        controlName,
        i18nFormFieldsPath,
        i18nGenericValidationsPath,
        validationsPreset,
    }: {
        control: UntypedFormControl;
        controlName: string;
        i18nFormFieldsPath: string;
        i18nGenericValidationsPath: string;
        validationsPreset: ValidationsPreset;
    }): string {
        const fieldPath = `${i18nFormFieldsPath}.${controlName}`;

        const genericError = this.checki18nGenericValidations({
            control,
            formCtrlName: controlName,
            fieldPath,
            genericValidationsPath: i18nGenericValidationsPath,
        });

        if (genericError) {
            return genericError;
        }

        const error = [...validationsPreset.get(controlName)].find((error) => control.hasError(error.key));

        return error ? this.getI18nLabel(`${fieldPath}.validators`, error.key, error.params) : '';
    }

    private checki18nGenericValidations(validationReq: i18nValidationCheckReq): string {
        let errorMsg = '';

        if (!validationReq?.control?.pristine ?? true) {
            const genericValidations = this.ts.instant(validationReq.genericValidationsPath);
            const genericValidationKeys = genericValidations ? Object.keys(genericValidations) : [];
            const overrideValidations = this.geti18nOverrideValidations(validationReq);

            genericValidationKeys.forEach((validationType) => {
                if (validationReq?.control.hasError(validationType)) {
                    const fieldLabel = this.getI18nLabel(validationReq.fieldPath, 'label');
                    const i18nGenericValidationPath = validationReq.genericValidationsPath + (validationType ? `.${validationType}` : '');
                    const i18nOverrideValidationsPath =
                        `${validationReq.fieldPath}.validators` + (validationType ? `.${validationType}` : '');

                    if (overrideValidations && overrideValidations[`${validationType}`]) {
                        errorMsg = this.ts.instant(i18nOverrideValidationsPath);
                    } else {
                        errorMsg = this.ts.instant(i18nGenericValidationPath, { fieldLabel: fieldLabel });
                    }

                    return false;
                }
                return true;
            });
        }

        return errorMsg ?? '';
    }

    private geti18nOverrideValidations(validationReq: i18nValidationCheckReq) {
        const i18nField = this.ts.instant(validationReq.fieldPath);
        return _.has(i18nField, 'validators') ? i18nField.validators : null;
    }

    public getI18nLabel(i18nBasePath: string, key?: string, interpolatedParams?: Record<string, unknown>) {
        return this.ts.instant(`${i18nBasePath}${key ? `.${key}` : ''}`, interpolatedParams);
    }

    public getCardIcon(brand: string) {
        return CARD_BRAND_ICONS.find((x) => x.brand?.toLocaleLowerCase() === brand?.toLocaleLowerCase())?.icon ?? null;
    }

    // Utility Functions
    public isForeignInvestor(initData: BazaNcBootstrapDto): boolean {
        return initData?.investorAccount?.status?.isForeign ?? true;
    }

    public isVerifiedInvestor(initData: BazaNcBootstrapDto) {
        return initData?.investorAccount?.isAccountVerificationCompleted;
    }

    public isAccreditedInvestor(initData: BazaNcBootstrapDto) {
        return initData?.investorAccount?.isAccreditedInvestor;
    }

    public isDwollaAvailable(initData: BazaNcBootstrapDto): boolean {
        return initData?.investorAccount?.isDwollaAvailable ?? false;
    }

    public isDwollaCashInAccountLinked(initData: BazaNcBootstrapDto): boolean {
        return initData?.investorAccount?.isBankAccountCashInLinked ?? false;
    }

    public isDwollaCashOutAccountLinked(initData: BazaNcBootstrapDto): boolean {
        return initData?.investorAccount?.isBankAccountCashOutLinked ?? false;
    }

    public isNCBankAccountAvailable(initData: BazaNcBootstrapDto): boolean {
        return initData?.investorAccount?.isBankAccountNcAchLinked ?? false;
    }

    public isCardAvailable(initData: BazaNcBootstrapDto): boolean {
        return initData?.investorAccount?.isCreditCardLinked ?? false;
    }

    public appSupportsRegCF(initData: BazaNcBootstrapDto) {
        return initData?.supportedNcRegulationTypes?.includes(BazaNcOfferingRegulationType.RegCF);
    }

    /* Cross Browser Event Handler Utility */
    public attachEventListener(el, ev, fn) {
        if (window.addEventListener) {
            // modern browsers including IE9+
            el.addEventListener(ev, fn, false);
        } else if (window['attachEvent']) {
            // IE8 and below
            el.attachEvent('on' + ev, fn);
        } else {
            el['on' + ev] = fn;
        }
    }

    public removeEventListener(el, ev, fn) {
        if (window.removeEventListener) {
            el.removeEventListener(ev, fn, false);
        } else if (window['detachEvent']) {
            el.detachEvent('on' + ev, fn);
        } else {
            el['on' + ev] = null;
        }
    }
}
