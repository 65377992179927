import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpRequestType } from '../enums/http-request-type.enum';
import _ from 'lodash';

@Injectable()
export class WhiteSpaceInterceptor implements HttpInterceptor {
    intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        if (req.method !== HttpRequestType.GET && req.method !== HttpRequestType.DELETE && req.body && !_.isEmpty(req.body)) {
            // recursively find & trim all whitespaces from objects/arrays and complex structures e.g. nested objects using lodash
            const recursiveMapValues = _.curry((fn, obj) =>
                _.transform(obj, (acc, value, key) => {
                    if (_.isObject(value)) {
                        acc[String(key)] = recursiveMapValues(fn, value);
                    } else if (_.isString(value)) {
                        acc[String(key)] = fn(value);
                    } else {
                        acc[String(key)] = value;
                    }
                }),
            );
            const trimmedRequestBody = _.isString(req.body) ? _.trim(req.body) : recursiveMapValues(_.trim, req.body);
            req = req.clone({
                body: trimmedRequestBody,
            });
        }

        return next.handle(req);
    }
}
