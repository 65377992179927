<div class="header-mini">
    <div class="container">
        <app-back-link
            [text]="wts.getI18nLabel(i18nBasePath, 'links.backLink.text')"
            [showLeaveConfirmation]="true"
            (backEvent)="showLeaveConfirmationModal = true"></app-back-link>

        <app-confirm-modal
            *ngIf="showLeaveConfirmationModal"
            [confirmBtnDanger]="false"
            (cancel)="showLeaveConfirmationModal = false"
            (confirm)="onConfirm()">
            <span
                modalTitle
                [innerHTML]="wts.getI18nLabel(i18nBasePath, 'links.backLink.confirmation.title')"></span>
            <span
                modalContent
                [innerHTML]="wts.getI18nLabel(i18nBasePath, 'links.backLink.confirmation.text')"></span>
        </app-confirm-modal>
    </div>
</div>

<router-outlet></router-outlet>
