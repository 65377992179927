import { PurchaseAgreementEnI18n } from '../agreement/i18n/agreement.i18n';
import { PurchaseDetailsEnI18n } from '../details/i18n/details.i18n';
import { PurchaseDoneEnI18n } from '../done/i18n/done.i18n';
import { PurchaseMethodsEnI18n } from '../methods/i18n/methods.i18n';
import { PurchasePaymentEnI18n } from '../payment/i18n/payment.i18n';
import { DwollaPaymentEditEnI18n } from '../ui-components/pf/i18n';
import { RegCFLimitEnI18n } from '../ui-components/regcf-limit/i18n/regcf-limit.i18n';
import { WithdrawEnI18n } from '../ui-components/withdraw/i18n/withdraw.i18n';

export const BazaWebPFEnI18n = {
    dwpf: {
        parent: {
            links: {
                pfLink: `/buy-shares`,
                vfLink: `/verification`,
                defaultRedirect: `/items/{{id}}`,
                backLink: {
                    text: 'Back to Listing Details',
                    confirmation: {
                        title: 'Are you sure you want to exit?',
                        text: 'Your purchase will not be completed if you leave this page.',
                    },
                },
            },
            steps: {
                detailsLabel: 'Purchase Details',
                agreementLabel: 'Sign Agreement',
                paymentLabel: 'Submit Payment',
            },
            warnings: {
                pickShares: 'Please pick number of shares',
            },
        },
        agreement: PurchaseAgreementEnI18n,
        details: PurchaseDetailsEnI18n,
        done: PurchaseDoneEnI18n,
        methods: PurchaseMethodsEnI18n,
        payment: PurchasePaymentEnI18n,
        pymtEdit: DwollaPaymentEditEnI18n,
        notifications: {
            cancel_purchase_fail: 'There was an error cancelling the Purchase.',
            load_bank_account_fail: 'Failed to load Bank Account information',
            load_account_balance_fail: 'Failed to load Account Balance information',
            load_credit_card_fail: 'Failed to load Credit Card information',
            save_bank_account_fail: 'Failed to save Bank Account information',
            delete_credit_card_fail: 'Failed to delete Credit Card',
            save_credit_card_fail: 'Failed to save Credit Card information',
            submit_purchase_fail: 'There was an error submitting the Purchase.',
            submit_purchase_success: 'Purchase successfully submitted',
            purchase_start_fail: 'Could not start the session',
            purchase_reprocess_success: 'Payment successfully re-submitted',
            purchase_reprocess_fail: 'Payment retry request failed. Please contact your bank for further details',
            plaid_on_link_success: `The bank account you're using to add funds to your account balance will be utilized to receive dividends. You can change it later on Account page.`,
            plaid_link_fail: 'There was an error linking bank account',
            stripe_link_fail: 'There was an error linking credit card',
        },
        regCfLimit: RegCFLimitEnI18n,
    },
    withdraw: WithdrawEnI18n,
};
