import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BazaRegistryNgService } from '@scaliolabs/baza-core-ng';
import { AppLink, ExtLink, I18nLinkConfig, LinkConfig, NavigationLink } from '../models';
import { BazaWebUtilSharedService } from './baza-web-util-shared.service';

@Injectable()
export class BazaLinkUtilSharedService {
    constructor(
        private readonly registry: BazaRegistryNgService,
        private readonly router: Router,
        private readonly wts: BazaWebUtilSharedService,
    ) {}

    public getI18nLinkConfig(linkConfigBasePath: string): I18nLinkConfig {
        return (this.wts.getI18nLabel(linkConfigBasePath) ?? {}) as I18nLinkConfig;
    }

    public getI18nLinkConfigs(linkConfigBasePath: string): Array<I18nLinkConfig> {
        const configuration = this.wts.getI18nLabel(linkConfigBasePath);
        return Array.isArray(configuration) ? configuration : [configuration];
    }

    public getUrl(linkToNavigate: NavigationLink): string {
        let url: string;
        if (linkToNavigate) {
            url = this.isAppLink(linkToNavigate) ? this.getUrlAppLink(linkToNavigate) : this.getUrlExtLink(linkToNavigate);
        }
        return (url ?? '').trim();
    }

    public getUrlFromLinkConfigPath(linkConfigPath: string): string {
        const linkToNavigate = this.getI18nLinkConfig(linkConfigPath)?.link;
        let url: string;

        if (linkToNavigate) {
            url = this.isAppLink(linkToNavigate) ? this.getUrlAppLink(linkToNavigate) : this.getUrlExtLink(linkToNavigate);
        }
        return (url ?? '').trim();
    }

    public getUrlText(linkToNavigate: NavigationLink): string {
        const urlText = this.isAppLink(linkToNavigate) ? this.getUrlTextAppLink(linkToNavigate) : this.getUrlTextExtLink(linkToNavigate);
        return this.cleanUrlText(urlText);
    }

    public navigate(linkToNavigate: NavigationLink): void {
        this.isAppLink(linkToNavigate) ? this.openAppLink(linkToNavigate) : this.openExtLink(linkToNavigate);
    }

    // #region Private Functions
    openAppLink(linkToNavigate: NavigationLink): void {
        const appLink = this.getAppLink(linkToNavigate);
        const url = this.getUrlAppLink(linkToNavigate);

        if (appLink.target) window.open(url, appLink.target ?? '_blank');
        else this.router.navigate(appLink.commands, appLink.navExtras);
    }

    getUrlTextAppLink(linkToNavigate: NavigationLink): string {
        const appLink = this.getAppLink(linkToNavigate);

        if (!appLink.text) {
            const urlTree = this.router.createUrlTree(appLink.commands, appLink.navExtras);
            return this.router.serializeUrl(urlTree) ?? '';
        }

        return appLink.text;
    }

    getUrlAppLink(linkToNavigate: NavigationLink): string {
        const appLink = this.getAppLink(linkToNavigate);
        const urlTree = this.router.createUrlTree(appLink.commands, appLink.navExtras);

        return this.router.serializeUrl(urlTree) ?? '';
    }

    openExtLink(linkToNavigate: NavigationLink): void {
        const extLink = this.getExtLink(linkToNavigate);
        const url = this.getUrlExtLink(extLink);

        window.open(url, extLink.target ?? '_blank');
    }

    getUrlExtLink(linkToNavigate: NavigationLink): string {
        const extLink = this.getExtLink(linkToNavigate);
        let url = this.isCMSLink(extLink) ? this.getCMSLinkFromReg(extLink.link) : extLink.link;

        if (this.isMailLink(extLink)) url = this.getMailLink(url);

        return url;
    }

    getUrlTextExtLink(linkToNavigate: NavigationLink): string {
        const extLink = this.getExtLink(linkToNavigate);
        const urlText = extLink.text ?? this.getUrlExtLink(extLink);
        return this.cleanUrlText(urlText);
    }

    getAppLink(linkToNavigate: NavigationLink): AppLink {
        return 'appLink' in linkToNavigate ? (linkToNavigate as LinkConfig).appLink : (linkToNavigate as AppLink);
    }

    getExtLink(linkToNavigate: NavigationLink): ExtLink {
        return 'extLink' in linkToNavigate ? (linkToNavigate as LinkConfig).extLink : (linkToNavigate as ExtLink);
    }

    getMailLink(link: string): string {
        return `mailto:${link}`;
    }

    getCMSLinkFromReg(linkId: string): string {
        return this.registry.value(linkId);
    }

    cleanUrlText(url: string) {
        return (url ?? '').replace('mailto:', '').trim();
    }

    // #endregion

    // #region Utility Functions
    isAppLink(linkToNavigate: NavigationLink): boolean {
        return 'appLink' in linkToNavigate || 'commands' in linkToNavigate ? true : false;
    }

    isExtLink(linkToNavigate: NavigationLink): boolean {
        return 'extLink' in linkToNavigate ? true : false;
    }

    isCMSLink(extLink: ExtLink): boolean {
        return 'isCMSLink' in extLink ?? false;
    }

    isOverrideCMSLinkText(extLink: ExtLink): boolean {
        return this.isCMSLink(extLink) && 'overrideCMSLinkText' in extLink ? true : false;
    }

    isMailLink(extLink: ExtLink): boolean {
        return 'isMailLink' in extLink ?? false;
    }

    // #endregion
}
