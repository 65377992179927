<div class="purchase-methods">
    <ng-container *ngIf="dataStream$ | async as data; else skeleton">
        <div class="purchase__subheading">
            {{ wts.getI18nLabel(i18nBasePath, 'title') }}
        </div>

        <!-- Account Balance Section -->
        <ng-container *ngIf="config?.showAccountBalance && !isForeignInvestor">
            <div
                class="purchase__row"
                nz-row
                nzGutter="30"
                *ngIf="showDwollaLowFundsError && accountBalanceEditMode">
                <div
                    class="purchase__info"
                    nz-col
                    nzXs="12">
                    <nz-alert
                        class="purchase__alert"
                        nzType="warning"
                        data-cy="dwolla-low-funds-error"
                        [nzMessage]="wts.getI18nLabel(i18nBasePath, 'errors.insufficientFunds')"></nz-alert>
                </div>
            </div>
            <div
                class="purchase__row"
                [class.purchase__row-add]="accountBalanceAddMode || !hasEnoughFunds"
                nz-row>
                <div
                    class="purchase__info"
                    nz-col
                    nzXs="12">
                    <app-payment-account-balance
                        [srcData]="dwollaCustomerDetail?.balance"
                        [config]="balanceConfig"></app-payment-account-balance>
                </div>
            </div>
        </ng-container>

        <!-- NC ACH Bank Account Section -->
        <ng-container *ngIf="config?.showBankAccount">
            <div
                class="purchase__row"
                [class.purchase__row-add]="bankConfig?.isAddModeOn"
                nz-row>
                <div
                    class="purchase__info"
                    nz-col
                    nzXs="12">
                    <app-payment-bank-account
                        [srcData]="data.bank"
                        [config]="bankConfig"
                        (upsertBank)="onBankLink()"></app-payment-bank-account>
                </div>
            </div>
        </ng-container>

        <!-- Credit Card Section -->
        <ng-container *ngIf="config?.showCreditCard">
            <div
                class="purchase__row"
                [class.purchase__row-add]="cardConfig?.isAddModeOn"
                nz-row>
                <div
                    class="purchase__info"
                    nz-col
                    nzXs="12">
                    <app-payment-card
                        [srcData]="data.card"
                        [limits]="limits$ | async"
                        [config]="cardConfig"
                        (upsertCard)="onUpsertCard()"></app-payment-card>
                </div>
            </div>
            <ng-template #tplStripeModalTitle>
                {{ wts.getI18nLabel(i18nBasePath, 'alerts.confirmUpdate.title') }}

                <div class="card-icon card-icon-header">
                    <ng-container *ngFor="let cardBrandIcon of cardBrandIcons">
                        <div class="card-icon__item">
                            <svg-icon-sprite [src]="cardBrandIcon.icon"></svg-icon-sprite>
                        </div>
                    </ng-container>
                </div>
            </ng-template>
        </ng-container>

        <!-- Edit CTA -->
        <a
            class="payment-subtitle payment-subtitle-cta"
            role="button"
            *ngIf="isPaymentMethodEditVisible"
            (click)="openPaymentEditModal()">
            {{ wts.getI18nLabel(i18nBasePath, 'actions.editMethod') }}
        </a>

        <app-payment-edit
            *ngIf="isPaymentEditModalVisible"
            [dwollaCustomerDetail]="dwollaCustomerDetail"
            [bankAccountResponse]="data.bank"
            [creditCardResponse]="data.card"
            [isPurchaseAboveLimit]="isPurchaseAboveLimit"
            [selectedPaymentMethod]="selectedPaymentMethod"
            [isForeignInvestor]="isForeignInvestor"
            [hasEnoughFunds]="hasEnoughFunds"
            [config]="config"
            [initData]="initData"
            (selectPaymentMethod)="onSelectPaymentMethodAndClose($event)"
            (handlePaymentEditCancel)="isPaymentEditModalVisible = false"
            (submitAddForm)="onSubmitAddFormFromModal($event)"></app-payment-edit>
    </ng-container>

    <ng-template #skeleton>
        <nz-skeleton [nzActive]="true"></nz-skeleton>
    </ng-template>
</div>
