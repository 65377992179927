<nz-modal
    [nzTitle]="wts.getI18nLabel(i18nBasePath, 'title')"
    [nzAutofocus]="null"
    [nzClosable]="true"
    [nzFooter]="null"
    [nzVisible]="true"
    nzWrapClassName="payment-funds-modal"
    (nzOnCancel)="withdrawFundsCancel.emit()"
    #withdrawFundsModalEl>
    <ng-container *nzModalContent>
        <div class="payment-subtitle payment-subtitle-block payment-subtitle-withdraw">{{ balanceLabel }}</div>

        <app-withdraw-funds-form
            #withdrawFundsFormEl
            [withdrawFundsForm]="withdrawFundsForm"
            [walletAmount]="walletAmount"
            [withdrawFundsError]="withdrawFundsError"></app-withdraw-funds-form>

        <div *ngIf="dwollaDefaultCashOutAccount">
            <div
                class="payment-funds__heading"
                [innerHTML]="wts.getI18nLabel(i18nBasePath, 'cashOut.heading') | sanitizeHtml"></div>

            <ng-container>
                <button
                    class="payment-btn payment-btn-borderless payment-btn-inactive payment-btn-details"
                    nz-button
                    nzBlock>
                    <div class="payment-btn__row">
                        <div
                            class="payment-btn__title"
                            [innerHTML]="dwollaDefaultCashOutAccount?.result?.accountName | sanitizeHtml"></div>
                        <div
                            class="payment-btn__descr"
                            [innerHTML]="dwollaDefaultCashOutAccount?.result?.accountType | sanitizeHtml"></div>
                    </div>
                </button>
            </ng-container>

            <button
                class="payment-funds__submit"
                nz-button
                nzBlock
                nzSize="large"
                nzType="primary"
                [disabled]="bazaFormValidatorService.isFormFilledAndValid(withdrawFundsForm)"
                (click)="onFormSubmit(withdrawFundsModalEl)">
                {{ wts.getI18nLabel(i18nBasePath, 'actions.transfer') }}
            </button>
        </div>
    </ng-container>
</nz-modal>
