<ng-container *ngIf="!wts.isForeignInvestor(initData)">
    <ng-container
        *ngIf="{
            cashOutAccount: dwollaDefaultCashOutAccount$ | async,
            dwollaCustomerDetail: dwollaCustomerDetail$ | async
        } as data">
        <div class="payment-funds payment-funds-withdraw">
            <div class="payment-funds__trigger">
                <ng-content select="[withdrawTrigger]"></ng-content>
            </div>

            <app-withdraw-funds-modal
                *ngIf="isWithdrawFundsModalVisible"
                [withdrawFundsForm]="withdrawFundsForm"
                [dwollaDefaultCashOutAccount]="data?.cashOutAccount"
                [walletAmount]="+(data?.dwollaCustomerDetail?.balance?.value ?? '0')"
                [withdrawFundsError]="withdrawFundsError$ | async"
                (withdrawFundsCancel)="onWithdrawFundsCancel()"
                (submitWithdrawFundsForm)="onSubmitWithdrawFundsForm()"></app-withdraw-funds-modal>

            <app-withdraw-funds-notification
                *ngIf="isWithdrawFundsNotificationVisible"
                (isVisibleChange)="isWithdrawFundsNotificationVisible = $event"></app-withdraw-funds-notification>
        </div>
    </ng-container>
</ng-container>
