<ng-container
    *ngIf="{
        verification: verification$ | async,
        formResources: formResources$ | async,
        listStates: listStates$ | async
    } as data">
    <form
        nz-form
        nzLayout="vertical"
        [formGroup]="verificationInfoForm"
        #verificationFormEl>
        <div class="steps-content">
            <div
                nz-row
                nzGutter="30">
                <div
                    nz-col
                    nzXs="12"
                    class="verification__section">
                    <div
                        class="verification__title"
                        data-cy="verification-info__title"
                        [innerHTML]="wts.getI18nLabel(i18nBasePath, 'title') | sanitizeHtml"></div>

                    <div
                        class="verification__descr"
                        [innerHTML]="wts.getI18nLabel(i18nBasePath, 'descr') | sanitizeHtml"></div>

                    <div
                        class="verification__descr"
                        [innerHTML]="wts.getI18nLabel(i18nBasePath, 'subtitle') | sanitizeHtml"></div>

                    <br />

                    <div
                        nz-row
                        nzGutter="30"
                        class="verification-info">
                        <div
                            nz-col
                            nzXs="12"
                            class="verification-info__first-name">
                            <nz-form-item>
                                <nz-form-label nzFor="firstName">
                                    {{ wts.getI18nLabel(i18nFormFieldsPath, 'firstName.label') }}
                                </nz-form-label>

                                <nz-form-control [nzErrorTip]="firstNameErrorTemplate">
                                    <input
                                        data-cy="info-first-name__input"
                                        formControlName="firstName"
                                        id="firstName"
                                        nz-input />
                                </nz-form-control>

                                <ng-template
                                    #firstNameErrorTemplate
                                    let-control>
                                    <span data-cy="info-first-name__error">
                                        {{ checki18nValidationErrorMessages(control, 'firstName') }}
                                    </span>
                                </ng-template>
                            </nz-form-item>
                        </div>

                        <div
                            nz-col
                            nzXs="12"
                            class="verification-info__last-name">
                            <nz-form-item>
                                <nz-form-label nzFor="lastName">
                                    {{ wts.getI18nLabel(i18nFormFieldsPath, 'lastName.label') }}
                                </nz-form-label>

                                <nz-form-control [nzErrorTip]="lastNameErrorTemplate">
                                    <input
                                        data-cy="info-last-name__input"
                                        formControlName="lastName"
                                        id="lastName"
                                        nz-input />
                                </nz-form-control>

                                <ng-template
                                    #lastNameErrorTemplate
                                    let-control>
                                    <span data-cy="info-last-name__error">
                                        {{ checki18nValidationErrorMessages(control, 'lastName') }}
                                    </span>
                                </ng-template>
                            </nz-form-item>
                        </div>

                        <div
                            nz-col
                            nzXs="12"
                            class="verification-info__phone">
                            <nz-form-item>
                                <nz-form-label nzFor="phone">
                                    {{ wts.getI18nLabel(i18nFormFieldsPath, 'phone.label') }}
                                </nz-form-label>

                                <nz-form-control [nzErrorTip]="phoneNumberErrorTemplate">
                                    <nz-input-group
                                        [nzAddOnBefore]="addOnBeforeTemplate"
                                        data-cy="info-phone__input">
                                        <input
                                            formControlName="phone"
                                            id="phone"
                                            nz-input
                                            maxlength="15"
                                            data-cy="info-phone__input-number" />
                                    </nz-input-group>

                                    <ng-template #addOnBeforeTemplate>
                                        <app-phone-code
                                            [numericCode]="numericCode"
                                            (codeChange)="onCodeChange($event)"
                                            *ngIf="numericCode"
                                            data-cy="info-phone__input-code"></app-phone-code>
                                    </ng-template>
                                </nz-form-control>
                                <ng-template
                                    #phoneNumberErrorTemplate
                                    let-control>
                                    <span data-cy="info-phone__error">
                                        {{ checki18nValidationErrorMessages(control, 'phone') }}
                                    </span>
                                </ng-template>
                                <nz-form-text>
                                    {{ wts.getI18nLabel(i18nFormFieldsPath, 'phone.subtitle') }}
                                </nz-form-text>
                            </nz-form-item>
                        </div>

                        <div
                            nz-col
                            nzXs="12"
                            class="verification-info__address">
                            <div class="verification__heading">
                                {{ wts.getI18nLabel(i18nFormPath, 'sections.address.title') }}
                            </div>

                            <div class="verification__descr">
                                {{ wts.getI18nLabel(i18nFormPath, 'sections.address.descr') }}
                            </div>

                            <nz-form-item>
                                <nz-form-label nzFor="residentialCountry">
                                    {{ wts.getI18nLabel(i18nFormFieldsPath, 'residentialCountry.label') }}
                                </nz-form-label>

                                <nz-form-control [nzErrorTip]="countryErrorTemplate">
                                    <nz-select
                                        data-cy="info-country__input"
                                        formControlName="residentialCountry"
                                        id="residentialCountry"
                                        [nzPlaceHolder]="wts.getI18nLabel(i18nFormFieldsPath, 'residentialCountry.selectPlaceholder')"
                                        [ngModel]="selectedResidentialCountry"
                                        (ngModelChange)="onCountryChange($event)"
                                        *ngIf="data.formResources?.listCountries">
                                        <nz-option
                                            [nzLabel]="item"
                                            [nzValue]="item"
                                            *ngFor="let item of data.formResources?.listCountries"></nz-option>
                                    </nz-select>
                                </nz-form-control>

                                <ng-template
                                    #countryErrorTemplate
                                    let-control>
                                    <span data-cy="info-country__error">
                                        {{ checki18nValidationErrorMessages(control, 'residentialCountry') }}
                                    </span>
                                </ng-template>
                            </nz-form-item>

                            <nz-form-item>
                                <nz-form-label nzFor="residentialStreetAddress1">
                                    {{ wts.getI18nLabel(i18nFormFieldsPath, 'residentialStreetAddress1.label') }}
                                </nz-form-label>

                                <nz-form-control [nzErrorTip]="streetErrorTemplate">
                                    <input
                                        data-cy="info-address-one__input"
                                        formControlName="residentialStreetAddress1"
                                        id="residentialStreetAddress1"
                                        nz-input />
                                </nz-form-control>

                                <ng-template
                                    #streetErrorTemplate
                                    let-control>
                                    <span data-cy="info-address-one__error">
                                        {{ checki18nValidationErrorMessages(control, 'residentialStreetAddress1') }}
                                    </span>
                                </ng-template>
                            </nz-form-item>

                            <nz-form-item>
                                <nz-form-label nzFor="streetAddress2">
                                    {{ wts.getI18nLabel(i18nFormFieldsPath, 'residentialStreetAddress2.label') }}
                                    <span class="ant-form-item-label-optional">
                                        &nbsp; {{ wts.getI18nLabel(i18nFormFieldsPath, 'residentialStreetAddress2.hint') }}
                                    </span>
                                </nz-form-label>

                                <nz-form-control>
                                    <input
                                        data-cy="info-address-two__input"
                                        formControlName="residentialStreetAddress2"
                                        id="residentialStreetAddress2"
                                        nz-input />
                                </nz-form-control>
                            </nz-form-item>

                            <nz-form-item>
                                <nz-form-label nzFor="residentialCity">
                                    {{ wts.getI18nLabel(i18nFormFieldsPath, 'residentialCity.label') }}
                                </nz-form-label>

                                <nz-form-control [nzErrorTip]="cityErrorTemplate">
                                    <input
                                        data-cy="info-city__input"
                                        formControlName="residentialCity"
                                        id="residentialCity"
                                        nz-input />
                                </nz-form-control>

                                <ng-template
                                    #cityErrorTemplate
                                    let-control>
                                    <span data-cy="info-city__error">
                                        {{ checki18nValidationErrorMessages(control, 'residentialCity') }}
                                    </span>
                                </ng-template>
                            </nz-form-item>
                        </div>

                        <div
                            *ngIf="data.listStates?.states.length"
                            nz-col
                            nzXs="12"
                            class="verification-info__state">
                            <nz-form-item>
                                <nz-form-label nzFor="residentialState">
                                    {{ wts.getI18nLabel(i18nFormFieldsPath, 'residentialState.label') }}
                                </nz-form-label>

                                <nz-form-control [nzErrorTip]="stateErrorTemplate">
                                    <nz-select
                                        data-cy="info-state__input"
                                        formControlName="residentialState"
                                        id="residentialState"
                                        [nzPlaceHolder]="wts.getI18nLabel(i18nFormFieldsPath, 'residentialState.selectPlaceholder')"
                                        [(ngModel)]="residentialState">
                                        <nz-option
                                            *ngFor="let state of data.listStates?.states"
                                            [nzLabel]="state.title"
                                            [nzValue]="state.value"></nz-option>
                                    </nz-select>
                                </nz-form-control>

                                <ng-template
                                    #stateErrorTemplate
                                    let-control>
                                    <span data-cy="info-state__error">
                                        {{ checki18nValidationErrorMessages(control, 'residentialState') }}
                                    </span>
                                </ng-template>
                            </nz-form-item>
                        </div>

                        <div
                            nz-col
                            nzXs="12"
                            class="verification-info__zip"
                            [ngClass]="{ 'verification-info__zip-expanded': !data.listStates?.states?.length }">
                            <nz-form-item>
                                <nz-form-label nzFor="residentialZipCode">
                                    {{ wts.getI18nLabel(i18nFormFieldsPath, 'residentialZipCode.label') }}
                                </nz-form-label>

                                <nz-form-control [nzErrorTip]="zipcodeErrorTemplate">
                                    <input
                                        data-cy="info-zip__input"
                                        formControlName="residentialZipCode"
                                        id="residentialZipCode"
                                        maxlength="11"
                                        nz-input />
                                </nz-form-control>

                                <ng-template
                                    #zipcodeErrorTemplate
                                    let-control>
                                    <span data-cy="info-zip__error">
                                        {{ checki18nValidationErrorMessages(control, 'residentialZipCode') }}
                                    </span>
                                </ng-template>
                            </nz-form-item>
                        </div>

                        <div
                            nz-col
                            nzXs="12"
                            class="verification-info__dob">
                            <nz-form-item>
                                <nz-form-label nzFor="dateOfBirth">
                                    {{ wts.getI18nLabel(i18nFormFieldsPath, 'dateOfBirth.label') }}
                                </nz-form-label>

                                <nz-form-control [nzErrorTip]="dobErrorTemplate">
                                    <nz-date-picker
                                        appAutocompleteOff
                                        data-cy="info-dob__input"
                                        formControlName="dateOfBirth"
                                        id="dateOfBirth"
                                        nzFormat="MM/dd/yyyy"
                                        nzPlaceHolder="MM/DD/YYYY"
                                        nzShowToday="false"
                                        appDateMask></nz-date-picker>
                                </nz-form-control>

                                <ng-template
                                    #dobErrorTemplate
                                    let-control>
                                    <span data-cy="info-dob__error">
                                        {{ checki18nValidationErrorMessages(control, 'dateOfBirth') }}
                                    </span>
                                </ng-template>
                            </nz-form-item>
                        </div>

                        <div
                            nz-col
                            nzXs="12"
                            class="verification-info__citizenship">
                            <nz-form-item>
                                <nz-form-label nzFor="citizenship">
                                    {{ wts.getI18nLabel(i18nFormFieldsPath, 'citizenship.label') }}
                                </nz-form-label>

                                <nz-form-control [nzErrorTip]="citizenshipErrorTemplate">
                                    <nz-select
                                        data-cy="info-citizenship__input"
                                        formControlName="citizenship"
                                        id="citizenship"
                                        [nzPlaceHolder]="wts.getI18nLabel(i18nFormFieldsPath, 'citizenship.selectPlaceholder')"
                                        *ngIf="data.formResources?.citizenship">
                                        <nz-option
                                            [nzLabel]="item.label"
                                            [nzValue]="item.citizenship"
                                            *ngFor="let item of data.formResources?.citizenship"></nz-option>
                                    </nz-select>
                                </nz-form-control>

                                <ng-template
                                    #citizenshipErrorTemplate
                                    let-control>
                                    <span data-cy="info-citizenship__error">
                                        {{ checki18nValidationErrorMessages(control, 'citizenship') }}
                                    </span>
                                </ng-template>
                            </nz-form-item>
                        </div>

                        <div
                            nz-col
                            nzXs="12">
                            <nz-form-item [ngClass]="{ 'ant-form-item-disabled': verificationInfoForm.value.hasntSsn }">
                                <nz-form-label nzFor="ssn">
                                    {{ wts.getI18nLabel(i18nFormFieldsPath, 'ssn.label') }}
                                </nz-form-label>
                                <nz-form-control [nzErrorTip]="ssnErrorTemplate">
                                    <input
                                        data-cy="info-ssn__input"
                                        formControlName="ssn"
                                        id="ssn"
                                        minlength="11"
                                        maxlength="11"
                                        nz-input
                                        autocomplete="new-password"
                                        #ssnEl
                                        (focus)="onSSNFocus()"
                                        (blur)="onSSNBlur()" />
                                </nz-form-control>

                                <ng-template
                                    #ssnErrorTemplate
                                    let-control>
                                    <span data-cy="info-ssn__error">
                                        {{ checki18nValidationErrorMessages(control, 'ssn') }}
                                    </span>
                                </ng-template>

                                <nz-form-text>
                                    {{ wts.getI18nLabel(i18nFormFieldsPath, 'ssn.hint') }}
                                </nz-form-text>
                            </nz-form-item>
                        </div>

                        <div
                            nz-col
                            nzXs="12">
                            <nz-form-item class="verification-info__hasntSsn">
                                <nz-form-control>
                                    <label
                                        data-cy="info-no-ssn__label"
                                        formControlName="hasntSsn"
                                        nz-checkbox>
                                        {{ wts.getI18nLabel(i18nFormFieldsPath, 'ssnCheckbox.label') }}
                                    </label>
                                </nz-form-control>
                            </nz-form-item>

                            <nz-form-item
                                *ngIf="verificationInfoForm.value.hasntSsn"
                                class="verification-info__file">
                                <div class="verification__descr">
                                    {{ wts.getI18nLabel(i18nFormFieldsPath, 'fileUpload.descr') }}
                                </div>

                                <div
                                    *ngIf="uploadedSSN.ssnDocumentFileName"
                                    data-cy="info-no-ssn__document">
                                    {{ wts.getI18nLabel(i18nFormFieldsPath, 'fileUpload.docLabel') }}

                                    <a
                                        href="{{ uploadedSSN.ssnDocumentUrl }}"
                                        target="_blank">
                                        {{ uploadedSSN.ssnDocumentFileName }}
                                    </a>
                                </div>

                                <nz-upload
                                    nzAccept=".png, .jpg, .jpeg, .pdf"
                                    [nzBeforeUpload]="onFileUpload"
                                    [(nzFileList)]="fileList"
                                    data-cy="info-upload__input">
                                    <button
                                        nz-button
                                        nzSize="small"
                                        nzType="default"
                                        type="button"
                                        data-cy="info-upload__button">
                                        {{ wts.getI18nLabel(i18nFormFieldsPath, 'fileUpload.btnText') }}
                                        <span *ngIf="fileList?.length > 0 || uploadedSSN.ssnDocumentFileName">&nbsp; New</span>
                                    </button>
                                </nz-upload>
                            </nz-form-item>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div
            nz-row
            nzGutter="30">
            <div
                nz-col
                nzXs="12"
                class="verification__steps-action">
                <div class="steps-action steps-note">
                    <button
                        class="btn-angle-right"
                        nz-button
                        nzSize="large"
                        nzType="primary"
                        type="submit"
                        (click)="onFormSubmit(verificationFormEl)"
                        data-cy="verification-info__submit">
                        {{ wts.getI18nLabel(i18nFormFieldsPath, 'submitBtn.label') }}
                    </button>
                </div>
            </div>
        </div>
    </form>
</ng-container>
