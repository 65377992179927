<ng-container *ngIf="cart$ | async as item; else skeleton">
    <div class="header-mini">
        <div class="container">
            <app-back-link
                [showLeaveConfirmation]="true"
                [text]="wts.getI18nLabel(i18nBasePath, 'links.backLink.text')"
                (backEvent)="showLeaveConfirmationModal = true"></app-back-link>

            <app-confirm-modal
                *ngIf="showLeaveConfirmationModal"
                [confirmBtnDanger]="false"
                (cancel)="showLeaveConfirmationModal = false"
                (confirm)="onConfirm()">
                <span
                    modalTitle
                    [innerHTML]="wts.getI18nLabel(i18nBasePath, 'links.backLink.confirmation.title')"></span>
                <ng-container modalContent>
                    <p
                        class="ant-modal__descr"
                        [innerHtml]="wts.getI18nLabel(i18nBasePath, 'links.backLink.confirmation.text')"></p>
                </ng-container>
            </app-confirm-modal>
        </div>
    </div>

    <div class="container purchase">
        <div
            nz-row
            nzGutter="30">
            <div
                nz-col
                nzXs="12"
                class="purchase__steps">
                <nz-steps
                    nzSize="small"
                    [nzCurrent]="currentTab">
                    <nz-step [nzTitle]="wts.getI18nLabel(i18nBasePath, 'steps.detailsLabel')"></nz-step>
                    <nz-step [nzTitle]="wts.getI18nLabel(i18nBasePath, 'steps.agreementLabel')"></nz-step>
                    <nz-step [nzTitle]="wts.getI18nLabel(i18nBasePath, 'steps.paymentLabel')"></nz-step>
                </nz-steps>
            </div>
        </div>

        <ng-container *ngIf="currentTab === 0">
            <ng-content select="[target=purchase-details]"></ng-content>
        </ng-container>

        <ng-container *ngIf="currentTab === 1">
            <ng-content select="[target=purchase-agreement]"></ng-content>
        </ng-container>

        <ng-container *ngIf="currentTab === 2">
            <ng-content select="[target=purchase-payment]"></ng-content>
        </ng-container>
    </div>
</ng-container>
<ng-template #skeleton>
    <nz-skeleton [nzActive]="true"></nz-skeleton>
</ng-template>
