import { CommonModule, CurrencyPipe, DatePipe, DecimalPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { IconSpriteModule } from 'ng-svg-icon-sprite';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzNotificationModule } from 'ng-zorro-antd/notification';
import {
    AppearDirective,
    AutocompleteOffDirective,
    I18nLinkifyDirective,
    PasswordInputDirective,
    SortElementsDirective,
} from './directives';
import { AmountMaskDirective } from './directives/amount-mask/amount-mask.directive';
import { DateMaskDirective } from './directives/date-mask/date-mask.directive';
import {
    CapitalizePipe,
    LastDigitsPipe,
    LastThreeDigitsPipe,
    LeadingZeroMonthPipe,
    MediaEmbedPipe,
    NumberPipe,
    NumberTwoDigitsPipe,
    PriceCentsPipe,
    PriceNoRoundPipe,
    PricePipe,
    SafeUrlPipe,
    SanitizeHtmlPipe,
} from './pipes';
import { BazaLinkUtilSharedService } from './services/baza-link-util-shared.service';
import { EffectsUtil } from './util';
import { BazaMktSectionsStore } from './services/baza-mkt-sections-store.service';

@NgModule({
    declarations: [
        AutocompleteOffDirective,
        LastDigitsPipe,
        LastThreeDigitsPipe,
        LeadingZeroMonthPipe,
        NumberPipe,
        PasswordInputDirective,
        PriceCentsPipe,
        PriceNoRoundPipe,
        PricePipe,
        SanitizeHtmlPipe,
        CapitalizePipe,
        NumberTwoDigitsPipe,
        DateMaskDirective,
        AmountMaskDirective,
        MediaEmbedPipe,
        SortElementsDirective,
        I18nLinkifyDirective,
        AppearDirective,
        SafeUrlPipe,
    ],
    imports: [CommonModule, IconSpriteModule, NzNotificationModule, NzModalModule, RouterModule, TranslateModule],
    exports: [
        AutocompleteOffDirective,
        IconSpriteModule,
        LastDigitsPipe,
        LastThreeDigitsPipe,
        LeadingZeroMonthPipe,
        NumberPipe,
        PasswordInputDirective,
        PriceCentsPipe,
        PriceNoRoundPipe,
        PricePipe,
        SanitizeHtmlPipe,
        CapitalizePipe,
        NumberTwoDigitsPipe,
        DateMaskDirective,
        AmountMaskDirective,
        MediaEmbedPipe,
        SortElementsDirective,
        TranslateModule,
        I18nLinkifyDirective,
        AppearDirective,
        SafeUrlPipe,
    ],
    providers: [
        CapitalizePipe,
        CurrencyPipe,
        DatePipe,
        DecimalPipe,
        EffectsUtil,
        LastDigitsPipe,
        LastThreeDigitsPipe,
        LeadingZeroMonthPipe,
        PriceCentsPipe,
        PriceNoRoundPipe,
        PricePipe,
        SanitizeHtmlPipe,
        NumberTwoDigitsPipe,
        MediaEmbedPipe,
        BazaLinkUtilSharedService,
        BazaMktSectionsStore,
    ],
})
export class UtilModule {}
