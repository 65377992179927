<div id="payout-method">
    <nz-alert
        *ngIf="dss.showDwollaAccountCreationError$ | async"
        class="payment-block__alert"
        nzType="error"
        data-cy="dwolla-available-error"
        [nzMessage]="dwollaAvailableError"></nz-alert>

    <nz-alert
        *ngIf="
            (dss.showDwollaAccountCreationError$ | async) === false &&
            !wts.isForeignInvestor(initData) &&
            !wts.isDwollaCashOutAccountLinked(initData) &&
            isAnyAssetPurchased
        "
        class="payment-block__alert"
        nzType="warning"
        data-cy="dwolla-available-warning"
        [nzMessage]="wts.getI18nLabel(i18nBasePath, 'alerts.addPayoutMethod')"></nz-alert>

    <app-payment-item
        [isPaymentLinked]="wts.isDwollaCashOutAccountLinked(initData)"
        [isDisabled]="dss.showDwollaAccountCreationError$ | async"
        (clickContainer)="onPayoutContainerClicked()"
        *ngIf="!wts.isForeignInvestor(initData)">
        <app-payment-item-header>
            {{ wts.getI18nLabel(i18nBasePath, 'title') }}

            <span
                class="payment-subtitle payment-subtitle-faded payment-subtitle-hint"
                *ngIf="wts.isDwollaCashOutAccountLinked(initData)">
                {{ wts.getI18nLabel(i18nBasePath, 'hint') }}
            </span>
        </app-payment-item-header>

        <ng-container *ngIf="wts.isDwollaCashOutAccountLinked(initData)">
            <app-payment-item-detail>{{ dwollaDefaultCashOutAccount?.result?.accountName }}</app-payment-item-detail>
            <app-payment-item-detail>{{ dwollaDefaultCashOutAccount?.result?.accountType }}</app-payment-item-detail>
        </ng-container>

        <app-payment-item-actions>
            <button
                nz-button
                nzSize="small"
                type="button"
                (click)="triggerDwollaPlaidFlow()">
                {{ wts.getI18nLabel(i18nBasePath, 'actions.update') }}
            </button>
        </app-payment-item-actions>
    </app-payment-item>

    <div
        *ngIf="wts.isForeignInvestor(initData)"
        class="payment-subtitle payment-subtitle-faded"
        [innerHTML]="wts.getI18nLabel(i18nBasePath, 'alerts.withdrawalSupport.label') | sanitizeHtml"
        appLinkify
        [linkConfigPath]="withdrawalSupportLinkConfig"></div>

    <div
        *ngIf="
            (dss.showDwollaAccountCreationError$ | async) === false &&
            !wts.isDwollaCashOutAccountLinked(initData) &&
            !wts.isForeignInvestor(initData)
        "
        class="payment-subtitle payment-subtitle-faded">
        {{ wts.getI18nLabel(i18nBasePath, 'alerts.linkBank') }}
    </div>

    <ng-template #dwollaAvailableError>
        <div
            [innerHTML]="wts.getI18nLabel(i18nBasePath, 'alerts.walletSupport.label') | sanitizeHtml"
            appLinkify
            [linkConfigPath]="walletSupportLinkConfig"></div>
    </ng-template>
</div>
