<div class="purchase-done">
    <div class="container">
        <div
            nz-row
            nzGutter="30"
            nzAlign="middle"
            class="purchase__row">
            <div
                nz-col
                nzXs="12"
                class="purchase__info">
                <div class="purchase-done__icon">
                    <svg-icon-sprite src="purchase-done"></svg-icon-sprite>
                </div>

                <div class="purchase__heading">
                    {{ wts.getI18nLabel(i18nBasePath, 'title') }}
                </div>

                <div class="purchase__descr purchase__descr-faded">
                    {{ wts.getI18nLabel(i18nBasePath, 'descr') }}
                </div>

                <div
                    class="purchase__descr purchase__descr-faded"
                    [innerHtml]="wts.getI18nLabel(i18nBasePath, 'support.descr') | sanitizeHtml"
                    appLinkify
                    [linkConfigPath]="contactLinkConfig"></div>

                <button
                    nz-button
                    nzSize="large"
                    nzType="primary"
                    class="purchase-done__btn"
                    [innerHtml]="wts.getI18nLabel(i18nBasePath, 'actions.proceedToPortfolio.label') | sanitizeHtml"
                    appLinkify
                    [linkConfigPath]="portfolioLinkConfig"></button>
            </div>

            <div
                nz-col
                nzXs="12"
                class="purchase__summary">
                <app-summary
                    [entity]="cart$ | async"
                    [numberOfShares]="numberOfShares$ | async"
                    [purchaseStart]="purchaseStart$ | async"></app-summary>
            </div>
        </div>
    </div>
</div>
